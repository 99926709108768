import { React, useState } from 'react';
import '../ui/main.css'; // Include the custom CSS file for animations

import NavBar from "../component/NavBar";
import Footer from "../component/Footer";
import Main from "../page/home-main";

const HomePage = () => {
  const [open, setOpen] = useState(false);

  return (
    <div className="relative min-h-screen bg-black text-white overflow-hidden flex flex-col">
      {/* Background Effect */}
      <div className="absolute inset-0 z-0">
        <div className="blur-spotlight"></div>
        <div className="blur-spotlight delay"></div>
        <div className="blur-spotlight delay-2"></div>
        <div className="blur-spotlight delay-3"></div>
      </div>

      {/* Navigation */}
      <NavBar open={open} setOpen={setOpen}/>

      <Main/>

      {/* Footer */}
     <Footer/>

    </div>
  );
};

export default HomePage;
