import React from 'react';
import { Stethoscope, Shield, Clock, Activity } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import '../ui/main.css'; // Include the custom CSS file for animations


const Main = () => {

  const navigate = useNavigate();

  const waitlistform = async () => {
    navigate('/waitlist');
  };

  return (
    <>
      <main className="relative z-10 flex-grow container mx-auto px-8 grid md:grid-cols-2 items-center gap-12 py-16">
        <div className="space-y-6">
          <h1 className="text-5xl font-bold leading-tight">
            Digital Healthcare <br />Simplified
          </h1>
          <p className="text-xl text-gray-300">
            Seamless patient management, secure communications, and intelligent health tracking—all in one platform.
          </p>
          <div className="flex space-x-4">
            <button onClick={waitlistform} className="bg-blue-600 text-white px-6 py-3 rounded-full hover:bg-blue-700 transition">
              Join Waitlist
            </button>
            <button className="border border-blue-600 text-blue-600 px-6 py-3 rounded-full hover:bg-blue-50 transition">
              Learn More
            </button>
          </div>
        </div>
        <div className="flex justify-center">
          <img 
            src="/media/MediHome-BG.png" 
            alt="Medical Digital Solution" 
            className="rounded-xl shadow-xl"
          />
        </div>
      </main>

      <section id="features" className="relative z-10 bg-opacity-50 backdrop-blur py-16">
        <div className="container mx-auto px-8 text-center">
          <h2 className="text-4xl font-bold mb-12">Our Key Features</h2>
          <div className="grid md:grid-cols-4 gap-8">
            {[
              { 
                icon: <Stethoscope className="w-12 h-12 mx-auto text-blue-400" />, 
                title: "Remote Consultations", 
                description: "Connect patients with healthcare professionals instantly." 
              },
              { 
                icon: <Shield className="w-12 h-12 mx-auto text-blue-400" />, 
                title: "Secure Data", 
                description: "HIPAA compliant platform with end-to-end encryption." 
              },
              { 
                icon: <Clock className="w-12 h-12 mx-auto text-blue-400" />, 
                title: "Efficient Scheduling", 
                description: "Smart appointment management and reminders." 
              },
              { 
                icon: <Activity className="w-12 h-12 mx-auto text-blue-400" />, 
                title: "Health Tracking", 
                description: "Comprehensive health metrics and insights." 
              }
            ].map((feature, index) => (
              <div key={index} className="bg-white bg-opacity-5 p-6 rounded-xl shadow-md hover:shadow-lg transition">
                {feature.icon}
                <h3 className="mt-4 text-xl font-semibold text-white">{feature.title}</h3>
                <p className="mt-2 text-gray-300">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
  </>
  );
};

export default Main;
