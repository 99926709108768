import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Authentication Pages
import HomePage from "./page/home";
import WaitListPage from "./page/waitform";
import WhyPage from "./page/WhyPage";

const App = () => {
  return (
    <Router>
      <div>
        <Routes>
          {/* WaitList Routes */}
          <Route path="/" element={<HomePage />} />
          <Route path="/waitlist" element={<WaitListPage />} />
          <Route path="/why" element={<WhyPage />} />

          {/* Default Route */}
          <Route path="/" element={<HomePage />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;