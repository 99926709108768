import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertCircle, CheckCircle } from 'lucide-react';
import { submitWaitlistForm } from '../services/authServices';

const WaitListPage = () => {
  const navigate = useNavigate();  // Get the navigate function

  const NavHome = () => {
    navigate('/');  // Navigate to the home page
  }

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    occupation: ''
  });
  const [submitStatus, setSubmitStatus] = useState({
    success: false,
    error: false,
    message: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await submitWaitlistForm(formData);
    
    setSubmitStatus({
      success: result.success,
      error: !result.success,
      message: result.message
    });

    if (result.success) {
      setFormData({
        name: '',
        email: '',
        occupation: ''
      });
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-black via-gray-800 to-black flex items-center justify-center p-4">
      <div className="bg-white/10 backdrop-blur-md shadow-lg rounded-3xl p-8 max-w-lg w-full">
        <button 
          className="fixed top-2 right-2 text-white text-4xl mt-2 mr-5"
          onClick={NavHome} 
        >
         &times;
        </button>

        <div className="text-center mb-6">
          <h1 className="text-3xl font-bold text-blue-400 mb-2">HealthConnect</h1>
          <p className="text-white/80 font-bold">Your Personal Health Revolution</p>
        </div>

        <div className="mb-6">
          <p className="text-white/70 mb-4 text-center">
            Get a chance to win a FREE medical device!
          </p>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <input 
            type="text" 
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            placeholder="Your Full Name"
            className="w-full p-3 bg-white/20 text-white placeholder-white/60 border border-white/30 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required 
          />
          <input 
            type="email" 
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            placeholder="Your Email Address"
            className="w-full p-3 bg-white/20 text-white placeholder-white/60 border border-white/30 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required 
          />
          <input 
            type="text" 
            name="occupation"
            value={formData.occupation}
            onChange={handleInputChange}
            placeholder="Your Occupation"
            className="w-full p-3 bg-white/20 text-white placeholder-white/60 border border-white/30 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required 
          />
          <button 
            type="submit" 
            className="w-full bg-blue-700 text-white p-3 rounded-lg hover:bg-blue-800 transition duration-300"
          >
            Join Waitlist
          </button>
        </form>

        {submitStatus.success && (
          <div className="mt-4 bg-green-800/30 border border-green-400 text-green-200 p-3 rounded-lg flex items-center">
            <CheckCircle className="mr-2" />
            {submitStatus.message}
          </div>
        )}

        {submitStatus.error && (
          <div className="mt-4 bg-red-800/30 border border-red-400 text-red-200 p-3 rounded-lg flex items-center">
            <AlertCircle className="mr-2" />
            {submitStatus.message}
          </div>
        )}

      </div>
    </div>

  );
};

export default WaitListPage;