import React from 'react';

const Footer = () => {

    return(
      <>
         <footer className="relative z-10 bg-white bg-opacity-5 p-6 rounded-xl shadow-md hover:shadow-lg">
            <div className="container mx-auto flex flex-col md:flex-row justify-between items-end md:items-center space-y-6 md:space-y-0">
              <p className="text-sm text-gray-400">
                Project By <a href="https://www.thryvetech.in" target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:underline">ThryveTech</a>
              </p>
              <div className="md:space-x-4 md:space-y-0 space-y-2 flex flex-col md:flex-row items-end">
                <a 
                  href="https://github.com/ThryveTech/" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="text-gray-400 hover:text-blue-400 transition"
                >
                  GitHub
                </a>
                <a 
                  href="https://www.instagram.com/ThryveTech" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="text-gray-400 hover:text-blue-400 transition"
                >
                  Instagram
                </a>
              </div>
            </div>
          </footer>

      </>
    );
  };

export default Footer;