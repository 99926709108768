import React from 'react';
import { Menu } from 'lucide-react';

const NavBar = ({ open, setOpen }) => {

    return(
      <>
        <nav className="relative z-20 mt-4 mx-8 px-8 py-4 flex justify-between items-center bg-gradient-to-r from-white/10 via-blue-300/5 to-white/10 backdrop-blur-md shadow-lg rounded-3xl flex-wrap md:space-y-0">
            <div className="md:text-3xl font-bold text-blue-400 flex items-center space-x-2 md:mb-0">
            <span>HealthConnect</span>
            </div>

            {/* Mobile Menu Toggle */}
            <div className="flex flex-wrap md:hidden justify-center md:justify-end items-center gap-4">
            <button className="text-white" onClick={() => setOpen(!open)}>
                <Menu />
            </button>
            </div>

            {/* Desktop Menu */}
            <div className="hidden md:flex md:flex-nowrap justify-center md:justify-end items-center gap-4">
            <a
                href="/why"
                className="flex items-center space-x-2 text-white hover:text-blue-400 transition"
            >
                <i className="lucide lucide-info-circle"></i>
                <span>Why</span>
            </a>
            <a
                href="#about"
                className="flex items-center space-x-2 text-white hover:text-blue-400 transition"
            >
                <i className="lucide lucide-user"></i>
                <span>About</span>
            </a>
            <button className="flex items-center space-x-2 bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition">
                <span className="text-white text-md">Get Started</span>
            </button>
            </div>
        </nav>
      
        {open && (
          <div className="fixed inset-0 flex items-start justify-center z-50">
            {/* Card Wrapper */}
            <div className="relative w-11/12 max-w-md bg-white/20 backdrop-blur-md rounded-3xl p-8 shadow-lg flex flex-col items-center space-y-6 mx-8 my-4">
              {/* Close Button */}
              <button
                className="absolute top-2 right-2 text-white text-4xl mt-1 mr-6"
                onClick={() => setOpen(false)}
              >
                &times;
              </button>
          
              {/* Menu Items */}
              <a href="/why" className="text-white text-2xl hover:text-blue-400 transition">
                Why
              </a>
              <a href="#about" className="text-white text-2xl hover:text-blue-400 transition">
                About
              </a>
              <button className="bg-blue-700 text-white px-6 py-3 rounded-md text-xl hover:bg-blue-800 transition">
                Get Started
              </button>
            </div>
          </div>

        )}
      </>
    );
  };

export default NavBar;