import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc } from 'firebase/firestore';

// Firebase configuration 
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  };

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export const submitWaitlistForm = async (formData) => {
  try {
    const deviceAllocated = Math.random() < 0.01;
    
    const docRef = await addDoc(collection(db, 'hc-waitlist'), {
      ...formData,
      timestamp: new Date(),
      deviceAllocated
    });

    return {
      success: true,
      message: deviceAllocated 
        ? "Congratulations! You're in top 1% with Winning List!"
        : 'You\'ve been added to our waitlist!',
      docId: docRef.id
    };
  } catch (error) {
    console.error("Firebase submission error:", error);
    return {
      success: false,
      message: 'Something went wrong. Please try again.'
    };
  }
};