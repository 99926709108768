import { React, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../ui/main.css'; // Include the custom CSS file for animations

import NavBar from "../component/NavBar";
import Footer from "../component/Footer";

const WhyPage = () => {

  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const waitlistform = async () => {
    navigate('/waitlist');
  };


  return (
    <div className="relative min-h-screen bg-black text-white overflow-hidden flex flex-col">
        {/* Background Effect */}
        <div className="absolute inset-0 z-0">
          <div className="blur-spotlight"></div>
          <div className="blur-spotlight delay"></div>
          <div className="blur-spotlight delay-2"></div>
          <div className="blur-spotlight delay-3"></div>
        </div>

        {/* Navigation */}
        <NavBar open={open} setOpen={setOpen}/>

        <main className="relative z-10 flex-grow container mx-auto px-8 grid md:grid-cols-2 items-center gap-12 py-16">
          <div className="flex justify-center">
            <img 
              src="/media/med-device.png" 
              alt="HealthConnect Solution" 
              className="rounded-xl shadow-xl"
            />
          </div>
          <div className="space-y-6">
            <h1 className="text-5xl font-bold leading-tight">
              Transforming Healthcare with <br />Personalized Insights
            </h1>
            <p className="text-xl text-gray-300">
              HealthConnect empowers you to track your health, understand your sleep, and make informed decisions every day.
            </p>
            <div className="flex space-x-4">
              <button 
                onClick={waitlistform} 
                className="bg-blue-600 text-white px-6 py-3 rounded-full hover:bg-blue-700 transition"
              >
                Join Waitlist
              </button>
              <button className="border border-blue-600 text-blue-600 px-6 py-3 rounded-full hover:bg-blue-50 transition">
                Learn More
              </button>
            </div>
          </div>  
        </main>

        <section id="why-healthconnect" className="relative z-10 py-16 bg-gradient-to-r from-blue-600/5 via-white/10 to-blue-300/5 backdrop-blur-md text-white">
          <div className="container mx-auto px-8 text-center">
            <h2 className="text-4xl font-bold mb-12 text-white">Why Choose HealthConnect?</h2>
            <p className="text-xl text-gray-300 mb-12">
              HealthConnect is more than just an app—it's a holistic platform that helps you take control of your health and well-being. 
              With a combination of intelligent tracking, personalized sleep analysis, and insightful recommendations, 
              we guide you to make healthier choices every day.
            </p>
            <div className="grid md:grid-cols-2 gap-8">
              <div className="bg-white bg-opacity-5 p-6 rounded-xl shadow-md hover:shadow-lg transition">
                <h3 className="mt-4 text-xl font-semibold text-white">Personalized Sleep Insights</h3>
                <p className="mt-2 text-gray-300">
                  Understand your sleep cycles and discover tips to improve your sleep quality.
                </p>
              </div>
              <div className="bg-white bg-opacity-5 p-6 rounded-xl shadow-md hover:shadow-lg transition">
                <h3 className="mt-4 text-xl font-semibold text-white">Track Your Health Data</h3>
                <p className="mt-2 text-gray-300">
                  Comprehensive health tracking features to help you monitor your well-being with ease.
                </p>
              </div>
            </div>
            <div className="grid md:grid-cols-2 gap-8 mt-8">
              <div className="bg-white bg-opacity-5 p-6 rounded-xl shadow-md hover:shadow-lg transition">
                <h3 className="mt-4 text-xl font-semibold text-white">Wake Up Smarter</h3>
                <p className="mt-2 text-gray-300">
                  Start your day with personalized suggestions based on your sleep data and health insights.
                </p>
              </div>
              <div className="bg-white bg-opacity-5 p-6 rounded-xl shadow-md hover:shadow-lg transition">
                <h3 className="mt-4 text-xl font-semibold text-white">Seamless Integration</h3>
                <p className="mt-2 text-gray-300">
                  Our platform integrates with your daily routine, offering smooth connectivity with wearables and devices.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section id="how-it-works" className="relative z-10 bg-opacity-50 backdrop-blur py-16">
          <div className="container mx-auto px-8 text-center">
            <h2 className="text-4xl font-bold mb-12">How It Works</h2>
            <p className="text-xl text-gray-200 mb-12">
              Our platform uses advanced data analytics, machine learning, and seamless integration with wearable devices to 
              collect and analyze your health metrics. HealthConnect gives you meaningful insights that help you optimize your lifestyle.
            </p>
            <div className="grid md:grid-cols-2 gap-8">
              <div className="bg-white bg-opacity-5 p-6 rounded-xl shadow-md hover:shadow-lg transition">
                <h3 className="mt-4 text-xl font-semibold text-white">Track & Analyze</h3>
                <p className="mt-2 text-gray-300">
                  We gather your health data and analyze it to offer insights into your habits and sleep patterns.
                </p>
              </div>
              <div className="bg-white bg-opacity-5 p-6 rounded-xl shadow-md hover:shadow-lg transition">
                <h3 className="mt-4 text-xl font-semibold text-white">Improve Your Health</h3>
                <p className="mt-2 text-gray-300">
                  Based on the analysis, we provide personalized suggestions to improve your sleep and overall health.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Footer */}
      <Footer/>

    </div>
  );
};

export default WhyPage;
